import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo.js"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import Header from "../../components/ServicesPage/MoviesPage/Header"
import DescriptionComponent from "../../components/ServicesPage/MoviesPage/DescriptionComponent"

const MoviesEN = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query MoviesEN {
      polish: contentfulMoviesServices(node_locale: { eq: "pl-PL" }) {
        slider1
        slider2
        button1
        button2
        text1 {
          raw
        }
        text2 {
          raw
        }
        text3 {
          raw
        }
        text4 {
          raw
        }
        text5 {
          raw
        }
      }
      english: contentfulMoviesServices(node_locale: { eq: "en-US" }) {
        slider1
        slider2
        button1
        button2
        text1 {
          raw
        }
        text2 {
          raw
        }
        text3 {
          raw
        }
        text4 {
          raw
        }
        text5 {
          raw
        }
      }
    }
  `)

  useEffect(() => {
    if (lang === "pl") {
      navigate("/uslugi/filmy/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Layout>
      <Seo title="Advertising videos" />
      <Header slider1={data.slider1} slider2={data.slider2} />
      <DescriptionComponent
        text1={data.text1}
        text2={data.text2}
        text3={data.text3}
        text4={data.text4}
        text5={data.text5}
        button1={data.button1}
        button2={data.button2}
      />
    </Layout>
  )
}

export default MoviesEN
